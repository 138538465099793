ion-card {
  border-radius: 15px;
}

ion-card.systemltr {
  background: var(--ion-color-light);
  color: var(--ion-color-dark);
  border-bottom-left-radius: 0px;
  margin-right: 100px;
}

ion-card.userltr {
  background: var(--ion-color-primary);
  color: var(--ion-color-dark);
  border-bottom-right-radius: 0px;
  margin-left: 100px;
}

ion-card.systemrtl {
  background: var(--ion-color-light);
  color: var(--ion-color-dark);
  border-bottom-right-radius: 0px;
  margin-left: 100px;
}

ion-card.userrtl {
  background: var(--ion-color-primary);
  color: var(--ion-color-dark);
  border-bottom-left-radius: 0px;
  margin-right: 100px;
}
